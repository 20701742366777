import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";

function PageCover({ title, subTitle, type = "services", bgImage }) {
  return (
    <section className={`page-cover section-margin ${type}`}>
      {!!bgImage && (
        <img className="page-cover-bg-img" alt="bg-img" src={bgImage} />
      )}
      <Header />

      <main className="px-3 d-flex align-items-center mx-auto flex-column  text-center">
        {!!title && <h1 className="heading">{title}</h1>}
        {!!subTitle && <p className="lead">{subTitle}</p>}
      </main>
    </section>
  );
}

PageCover.propTypes = {
  type: PropTypes.oneOfType(["services", "contact-us", "about-us"]),
  bgImage: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default PageCover;
