import React from "react";
import { Col, Container, Row } from "reactstrap";
import { PageCover } from "../components";

import WhoWeAreImg from "../assets/images/AboutUs.svg";

function AboutUs() {
  return (
    <div className="about-us-page">
      <PageCover title="About Us" type="about-us" />

      <section className="who-are-we section-margin">
        <Container>
          <Row className="gx-5 gy-5 align-items-center">
            <Col sm={6}>
              <h1 className="heading">Who</h1>
              <h2 className="sub-heading">we Are?</h2>

              <p className="content">
                We are a team of experienced developers and designers, ready to
                help you build your web and mobile applications. Our services
                also cover the entire spectrum of digital marketing and graphic
                design. By applying modern design principles and using latest
                mobile and web technologies, we create tailored solutions that
                add value and simplify business processes for you. We believe in
                finding big answers to big problems, solving them and inspiring
                others to do the same.
              </p>
            </Col>
            <Col sm={{ size: 5, order: 2, offset: 1 }}>
              <img src={WhoWeAreImg} className="img-fluid" alt="images" />
            </Col>
          </Row>
        </Container>
      </section>

      {/*<section className="meet-our-team">*/}
      {/*  <Container>*/}
      {/*    <h1 className="title text-center">Meet Our Creative Team</h1>*/}
      {/*    <Slider {...settings} className="team-slider">*/}
      {/*      <div className="item member">*/}
      {/*        <img src={MaleImg} className="member-icon img-fluid" alt="img" />*/}
      {/*        <h3 className="name">Shoaib Ali</h3>*/}
      {/*        <p className="designation">Chief Executive Officer</p>*/}
      {/*      </div>*/}
      {/*      <div className="item member">*/}
      {/*        <img src={MaleImg} className="member-icon img-fluid" alt="img" />*/}
      {/*        <h3 className="name">Faisal Shahzad</h3>*/}
      {/*        <p className="designation">Chief Technology Officer</p>*/}
      {/*      </div>*/}
      {/*      <div className="item member">*/}
      {/*        <img*/}
      {/*          src={FemaleImg}*/}
      {/*          className="member-icon img-fluid"*/}
      {/*          alt="img"*/}
      {/*        />*/}
      {/*        <h3 className="name">Asfa Sajid</h3>*/}
      {/*        <p className="designation">Project Management Officer</p>*/}
      {/*      </div>*/}
      {/*      <div className="item member">*/}
      {/*        <img src={MaleImg} className="member-icon img-fluid" alt="img" />*/}
      {/*        <h3 className="name">Bilal Afridi</h3>*/}
      {/*        <p className="designation">Team Lead</p>*/}
      {/*      </div>*/}
      {/*      <div className="item member">*/}
      {/*        <img src={MaleImg} className="member-icon img-fluid" alt="img" />*/}
      {/*        <h3 className="name">Syed Habib Shah</h3>*/}
      {/*        <p className="designation">Marketing Executive</p>*/}
      {/*      </div>*/}

      {/*      <div className="item member">*/}
      {/*        <img src={MaleImg} className="member-icon img-fluid" alt="img" />*/}
      {/*        <h3 className="name">Wajid Ali</h3>*/}
      {/*        <p className="designation">Designer</p>*/}
      {/*      </div>*/}
      {/*      <div className="item member">*/}
      {/*        <img src={MaleImg} className="member-icon img-fluid" alt="img" />*/}
      {/*        <h3 className="name">Ali Hasnain</h3>*/}
      {/*        <p className="designation">Android Developer</p>*/}
      {/*      </div>*/}
      {/*      <div className="item member">*/}
      {/*        <img src={MaleImg} className="member-icon img-fluid" alt="img" />*/}
      {/*        <h3 className="name">Nouman Ahmed</h3>*/}
      {/*        <p className="designation">Front End Developer</p>*/}
      {/*      </div>*/}
      {/*      <div className="item member">*/}
      {/*        <img src={MaleImg} className="member-icon img-fluid" alt="img" />*/}
      {/*        <h3 className="name">Muneeb</h3>*/}
      {/*        <p className="designation">Android Developer</p>*/}
      {/*      </div>*/}
      {/*    </Slider>*/}
      {/*  </Container>*/}
      {/*</section>*/}
    </div>
  );
}

export default AboutUs;
