import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import { NavLink } from "react-router-dom";

import SectionCardImg from "../assets/images/Sec.svg";
import SectionBgImg from "../assets/images/section.png";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
function CardSection({ title }) {
  return (
    <Container className="card-section py-5">
      <img className="card-bg-img" src={SectionBgImg} alt="bg-images" />
      <Row>
        <Col md={8} sm={7} className="align-self-end px-5">
          <h2 className="heading">{title}</h2>
          <NavLink
            className="btn contant-us"
            to="/contact-us"
            onClick={scrollToTop}
          >
            Contact Us
          </NavLink>
        </Col>
        <Col md={4} sm={5}>
          <img src={SectionCardImg} className="img-fluid" alt="images" />
        </Col>
      </Row>
    </Container>
  );
}

CardSection.propTypes = {
  title: PropTypes.string,
};

export default CardSection;
