import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Collapse,
  NavbarText,
} from "reactstrap";

import AppLogo from "../assets/icons/logo.svg";

function Header(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Container className="app-header">
      <Navbar dark expand="md">
        <NavbarBrand>
          <NavLink to="/home">
            <img
              src={AppLogo}
              className="d-inline-block align-top"
              alt="Website Logo"
              width="175px"
            />
          </NavLink>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav navbar>
            <NavItem>
              <NavLink className="nav-link" activeClassName="active" to="/home">
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                activeClassName="active"
                to="/about-us"
              >
                About Us
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                activeClassName="active"
                to="/services"
              >
                Services
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                activeClassName="active"
                to="/contact-us"
              >
                Contact Us
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </Container>
  );
}

export default Header;
