import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import ServicesIcon from "../assets/icons/app_icon.png";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
function ServiceCard({ title = "", description = "" }) {
  return (
    <div className="services-card  p-4  mb-4">
      <img alt="services-icon" className="services-icon" src={ServicesIcon} />
      <h2 className="title">
        {title} <span class="glyphicon glyphicon-envelope"></span>
      </h2>
      <p className="description">{description}</p>
      <NavLink className="nav-link" to="/services" onClick={scrollToTop}>
        Read More &nbsp; <i className="bi bi-arrow-right"></i>
      </NavLink>
    </div>
  );
}

ServiceCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ServiceCard;
