import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// src
import { AboutUs, Home, ContactUs, Services } from "./views";
import { ScrollButton, Layout } from "./components";

import "./styles/App.scss";

function App() {
  return (
    <Router>
      <Switch>
        <Layout>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/about-us">
            <AboutUs />
          </Route>
          <Route exact path="/contact-us">
            <ContactUs />
          </Route>
          <Route exact path="/services">
            <Services />
          </Route>
          <Route path="/*">
            <Redirect to="/home" />
          </Route>
        </Layout>
      </Switch>
      <ScrollButton />
    </Router>
  );
}

export default App;
