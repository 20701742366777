import React, { useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import { NavLink } from "react-router-dom";
import { ServiceCard, Header, CardSection } from "../components";

// Images
import ScrollBtn from "../assets/icons/scroll-button.svg";
import WhoWeAreImg from "../assets/images/AboutUs.svg";
import SrvBgImg from "../assets/images/servicesBG.png";
import ContactUsImg from "../assets/images/contact.svg";
import ContactBgImg from "../assets/images/contactBG.png";

const AboutUsSection = () => {
  return (
    <section className="who-are-we section-margin">
      <Container>
        <Row className="align-items-center">
          <Col sm={6}>
            <h1 className="heading">Who</h1>
            <h2 className="sub-heading">we Are?</h2>

            <p className="content">
              We are a team of experienced developers and designers, ready to
              help you build your web and mobile applications. Our services also
              cover the entire spectrum of digital marketing and graphic design.
              By applying modern design principles and using latest mobile and
              web technologies, we create tailored solutions that add value and
              simplify business processes for you. We believe in finding big
              answers to big problems, solving them and inspiring others to do
              the same.
            </p>
          </Col>
          <Col sm={{ size: 5, order: 2, offset: 1 }}>
            <img src={WhoWeAreImg} className="img-fluid" alt="images" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const ContactUsSection = () => {
  return (
    <section className="contact-us section-margin">
      <img className="contact-bg-img" src={ContactBgImg} alt="bg-images" />
      <Container>
        <Row>
          <Col sm={5} className="align-self-center info  text-center">
            <img
              alt="contact-us-img"
              src={ContactUsImg}
              className="img-fluid"
            />
            <p className="text">If you have any query please send us a mail.</p>
          </Col>
          <Col sm={7} className="contact-us-form">
            <h1 className="title mb-4">Get In Touch With Us</h1>

            <Form
              action="mailto:contact@albasirr.com"
              method="post"
              enctype="text/plain"
            >
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    bsSize="lg"
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    bsSize="lg"
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col sm={12}>
                  <Input
                    type="textarea"
                    name="message"
                    placeholder="Message"
                    id="message"
                    bsSize="lg"
                    rows={5}
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col sm={12}>
                  <Button color="primary">Send</Button>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const ServicesSection = () => {
  return (
    <section className="services ">
      <img className="service-bg-img" src={SrvBgImg} alt="bg-images" />
      <Container>
        <Row className="">
          <Col sm={4} className="align-self-center">
            <h1 className="heading">
              Our
              <br />
              Services
            </h1>
            <h2 className="sub-heading">
              Looking for
              <br />
              Quick Solution?
            </h2>
            <NavLink className="btn contant-us" to="/contact-us">
              Contact Us
            </NavLink>
          </Col>
          <Col sm={4}>
            <ServiceCard
              title="Mobile App Development"
              description="Mobile applications create amazing new possibilities for
businesses and products. We are into designing and
developing iOS and Android apps that are custom built
for your product's requirements."
            />
            <ServiceCard
              title="Website Development"
              description="Web applications now provide great levels of interactivity
and usability. We build powerful and scalable websites
that are custom made according to your requirements,
using latest web technologies."
            />
          </Col>
          <Col sm={4} className="pt-4">
            <ServiceCard
              title="Graphic Design"
              description="Graphic designs have become personalized and more
interactive over time. We combine our expertise in
research, strategy and design to create engaging
brands and digital marketing solutions."
            />
            <ServiceCard
              title="Digital Marketing"
              description="The mass adoption of the internet into everyday life has
glorified digital marketing over the last three decades.
We elevate your brand's presence with custom digital
marketing strategies."
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

function Home(props) {
  useEffect(() => {
    // array with texts to type in typewriter
    const dataText = [
      "Mobile App Development",
      "Website Development",
      "Digital Marketing",
      "Graphic Design",
    ];

    // type one text in the typwriter
    // keeps calling itself until the text is finished
    function typeWriter(text, i, fnCallback) {
      // chekc if text isn't finished yet
      if (i < text.length) {
        // add next character to h1
        const element = document.getElementById("typing-effect");
        if (element === null) return;

        element.innerHTML =
          text.substring(0, i + 1) + '<span aria-hidden="true"></span>';

        // wait for a while and call this function again for next character
        setTimeout(function () {
          typeWriter(text, i + 1, fnCallback);
        }, 100);
      }
      // text finished, call callback if there is a callback function
      else if (typeof fnCallback == "function") {
        // call callback after timeout
        setTimeout(fnCallback, 700);
      }
    }

    // start a typewriter animation for a text in the dataText array
    function StartTextAnimation(i) {
      if (typeof dataText[i] == "undefined" || dataText[i] === undefined) {
        setTimeout(function () {
          StartTextAnimation(0);
        }, 50000);
      }

      // check if dataText[i] exists
      if (dataText[i] !== undefined && i < dataText[i].length) {
        // text exists! start typewriter animation
        typeWriter(dataText[i], 0, function () {
          // after callback (and whole text has been animated), start next text
          StartTextAnimation(i + 1);
        });
      }
    }
    // start the text animation
    StartTextAnimation(0);
  }, []);

  return (
    <div className="home-container">
      {/* Home Page Cover */}
      <section className="cover-container">
        <Header />

        <main className="px-3 d-flex align-items-center mx-auto flex-column  text-center">
          <h1 className="heading">
            We Turn Your Ideas Into Revolutionary Products and Services
          </h1>
          <p className="lead" id="typing-effect"></p>
          <div className="text-white-50 scroll-btn">
            <img src={ScrollBtn} alt="scroll-btn" height="60px" />
          </div>
        </main>
      </section>

      {/* Who are We Section */}
      <AboutUsSection />

      {/* Services Section */}
      <ServicesSection />

      {/* Contact Us Section */}
      <ContactUsSection />

      {/* Contact Us Card Section */}
      <section className="home-card-section section-margin">
        <CardSection
          title={
            <>
              Want to See Your Idea as the
              <br />
              Next Big Thing?
            </>
          }
        />
      </section>
    </div>
  );
}

Home.propTypes = {};

export default Home;
