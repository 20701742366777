import React from "react";
import { Col, Container, Row, List, ListInlineItem, Button } from "reactstrap";

import AppLogo from "../assets/icons/logo.svg";

function Footer(props) {
  return (
    <div className="app-footer">
      <div className="upper-area">
        <Container>
          <Row>
            <Col sm={8}>
              <img
                src={AppLogo}
                className="d-inline-block align-top"
                alt="Website Logo"
                width="175px"
              />
              <List type="unstyled" className="info">
                <li>
                  <span>
                    <i className="bi bi-telephone-fill" />
                  </span>
                  +92 330 5044448
                </li>
                <li>
                  <span>
                    <i className="bi bi-envelope-open-fill" />
                  </span>
                  contact@albasirr.com
                </li>
                <li>
                  <span>
                    <i className="bi bi-geo-alt-fill" />
                  </span>
                  Office 1109, <br />
                  NSTP NUST H-12, Islamabad
                </li>
              </List>
            </Col>
            <Col sm={4}>
              <h3>Follow us on</h3>
              <List type="inline" className="social-media-links">
                <ListInlineItem>
                  <Button outline className="rounded-circle ">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/albasirr.technologies" rel="noreferrer"
                    >
                      <i
                        className="bi bi-facebook p-0"
                        style={{ fontSize: "1.2rem", color: "#fff" }}
                      ></i>
                    </a>
                  </Button>
                </ListInlineItem>
                <ListInlineItem>
                  <Button outline className="rounded-circle ">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/al-basirr-technologies" rel="noreferrer"
                    >
                      <i
                        className="bi bi-linkedin p-0"
                        style={{ fontSize: "1.2rem", color: "#fff" }}
                      ></i>
                    </a>
                  </Button>
                </ListInlineItem>
                <ListInlineItem>
                  <Button outline className="rounded-circle ">
                    <a target="_blank" href="https://twitter.com/albasirr_tech" rel="noreferrer">
                      <i
                        className="bi bi-twitter p-0"
                        style={{ fontSize: "1.2rem", color: "#fff" }}
                      ></i>
                    </a>
                  </Button>
                </ListInlineItem>
              </List>
              <h3>Team members login</h3>
              <List type="inline" className="social-media-links">
                <ListInlineItem>
                  <a target="_blank" href="https://albasirr.com/emails">
                    <Button outline>Email</Button>
                  </a>
                </ListInlineItem>
              </List>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bottom-area">
        <p className="text-center m-0">
          &#169; Copyright 2024, Al-Basirr Technologies (SMC) PVT LTD
        </p>
      </div>
    </div>
  );
}

Footer.propTypes = {};

export default Footer;
