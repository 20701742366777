import React from "react";
import PropTypes from "prop-types";

// src
import Footer from "./Footer";

function Layout({ children }) {
  return (
    <div className="app-layout">
      {children}
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.array,
};

export default Layout;
