import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { PageCover } from "../components";

function ContactUs(props) {
  return (
    <div className="contact-us-page">
      <PageCover title="Contact Us" type="contact-us" />
      <section className="info-block section-margin">
        <Container>
          <Row>
            <Col sm={4}>
              <div className="info-card text-center align-self-center">
                <i className="bi bi-telephone icon" style={{ fontSize: 45 }} />
                <p className="text">+92 330 5044448</p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="info-card text-center align-self-center">
                <i className="bi bi-envelope icon" style={{ fontSize: 45 }} />
                <p className="text">contact@albasirr.com</p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="info-card text-center align-self-center">
                <i className="bi bi-geo-alt icon" style={{ fontSize: 45 }} />
                <p className="text">
                  Office 1109, <br />
                  NSTP NUST H-12, Islamabad
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="contact-us-form ">
        <Container>
          <h1 className="title text-center">Get In Touch With Us</h1>
          <Form
            action="mailto:contact@albasirr.com"
            method="post"
            enctype="text/plain"
          >
            <FormGroup row>
              <Col sm={12}>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  bsSize="lg"
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12}>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  bsSize="lg"
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col sm={12}>
                <Input
                  type="textarea"
                  name="message"
                  placeholder="Message"
                  id="message"
                  bsSize="lg"
                  rows={5}
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col sm={12}>
                <Button color="primary" className="w-100" size="lg">
                  Send
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </Container>
      </section>
    </div>
  );
}

ContactUs.propTypes = {};

export default ContactUs;
