import React from "react";
import { Col, Container, Row } from "reactstrap";
import { CardSection, PageCover } from "../components";

import MobileDevImg from "../assets/icons/app-development.svg";
import GraphicDesignImg from "../assets/icons/graphic-design.svg";
import WebisteImg from "../assets/icons/website.svg";
import DigitalMarketingImg from "../assets/icons/digital-marketing.svg";

const ServiceSection = ({
  title,
  description,
  image,
  reverseDirection = false,
}) => {
  return (
    <Container className="service-container">
      <Row className={reverseDirection ? "flex-row-reverse" : ""}>
        <Col
          sm={reverseDirection ? { size: 6, offset: 1 } : { size: 6 }}
          className="align-self-center"
        >
          {!!title && <h1 className="title">{title}</h1>}
          {!!description && <p className="description">{description}</p>}
        </Col>
        <Col
          sm={reverseDirection ? { size: 5 } : { size: 5, order: 2, offset: 1 }}
        >
          {!!image && <img src={image} className="img-fluid" alt="images" />}
        </Col>
      </Row>
    </Container>
  );
};

function Services(props) {
  return (
    <div className="service-page ">
      <PageCover title="Our Services" type="services" />

      <section>
        <ServiceSection
          title="Mobile App Development"
          description="Mobile App Development Mobile applications create
          amazing new possibilities for businesses and products. We are
          into designing and developing iOS and Android apps that are
          custom built for your product's requirements. We offer
          concrete mobile solutions within the budget and as per the
          timelines."
          image={MobileDevImg}
        />
        <ServiceSection
          title="Graphic Design"
          description="Graphic designs have become personalized and more
          interactive over time. We combine our expertise in research,
          strategy and design to create engaging brands and digital
          marketing solutions. We create designs for you that emphasize
          your content and maximize the impression."
          image={GraphicDesignImg}
          reverseDirection
        />
        <ServiceSection
          title="Digital Marketing"
          description="The mass adoption of the internet into everyday life has
          glorified digital marketing over the last three decades. We
          elevate your brand's presence with custom digital marketing
          strategies. We bring home more traffic and generate amazing
          brand loyalty online."
          image={DigitalMarketingImg}
        />
        <ServiceSection
          title="Website Development"
          description="Web applications now provide great levels of interactivity and
          usability. We build powerful and scalable websites that are
          custom made according to your requirements, using latest
          web technologies. The key to our success is dedication and
          responsibility to deliver world-class websites."
          image={WebisteImg}
          reverseDirection
        />
      </section>

      {/* Contact Us Card Section */}
      <section className="">
        <CardSection
          title={
            <>
              Want to See Your Idea as the
              <br />
              Next Big Thing?
            </>
          }
        />
      </section>
    </div>
  );
}

export default Services;
